import _setPrototypeOf from "./setPrototypeOf.js";
import _isNativeReflectConstruct from "./isNativeReflectConstruct.js";
var exports = {};
var setPrototypeOf = _setPrototypeOf;
var isNativeReflectConstruct = _isNativeReflectConstruct;

function _construct(Parent, args, Class) {
  if (isNativeReflectConstruct()) {
    exports = _construct = Reflect.construct, exports.__esModule = true, exports["default"] = exports;
  } else {
    exports = _construct = function _construct(Parent, args, Class) {
      var a = [null];
      a.push.apply(a, args);
      var Constructor = Function.bind.apply(Parent, a);
      var instance = new Constructor();
      if (Class) setPrototypeOf(instance, Class.prototype);
      return instance;
    }, exports.__esModule = true, exports["default"] = exports;
  }

  return _construct.apply(null, arguments);
}

exports = _construct, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;